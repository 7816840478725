<template>
    <div style="width: 100%;padding: 0px 20px;box-sizing: border-box;">
        <div class="flex items-center mb-4 mt-3 justify-between">
            <div class="flex items-center">
                <i class="el-icon-arrow-left font-bold text-xl cursor-pointer" style="color: #999999" @click="goBack" />
                <p class="text-xl ml-2 font-bold" style="color: #ff8e3b"> {{ classInfo.class_info.class_name }}
                </p>
            </div>
        </div>

        <div class="class-content">
            <div class="left-container">
                <div class="desc"></div>
            </div>
            <div class="right-content">
                <div v-if="classApplicationInfo == null || classApplicationInfo.status == '待缴费'">
                    <el-button type="primary" size="small" @click="gotoOrderView()">Purchase</el-button>
                </div>
                <div v-else>
                    <div>
                        <el-button type="primary" size="small" style="width: 100%;" @click="gotoCourseHome()">Enter
                            Course</el-button>
                    </div>
                    <div>
                        <el-button type="primary" size="small" style="width: 100%;margin-top: 15px;"
                            @click="exchangeShowDataType"> {{ showDataType ==
                                'progress' ? 'Error Collection' : 'Progress' }}</el-button>
                    </div>
                </div>
            </div>
        </div>

        <el-table v-if="showDataType == 'progress'" :data="contentList" style="font-size: 12px;box-sizing: border-box;"
            empty-text="No Data" :highlight-current-row="false" :highlight-selection-row="false"
            :header-cell-class-name="'custom-head-cell'" :cell-style="tableCellstyle" :row-class-name="'custom-row-bg'">
            <el-table-column prop="session_name" label="Lesson"></el-table-column>
            <el-table-column prop="content_info.content_name" label="Content"></el-table-column>
            <el-table-column prop="" label="Blocks">
                <template slot-scope="scope">
                    {{ scope.row.block_ids.length }}
                </template>
            </el-table-column>
            <el-table-column prop="" label="Progress">
                <template slot-scope="scope">
                    {{ formatProgress(scope.row) }}
                </template>
            </el-table-column>
        </el-table>



        <div v-if="showDataType == 'error_collection'"
            style="margin-top: 40px;width: 100%;padding-left:84px;box-sizing: border-box">
            <el-collapse v-model="activeName" accordion>
                <el-collapse-item v-for="(errorInfo, index) in errorList"
                    :title="(index + 1) + '.' + errorInfo.quiz.question_title" :name="'' + (index + 1)" :key="index"
                    style="margin-bottom: 20px">
                    <div style="margin-top: 10px;width: 100%;padding-left: 40px;box-sizing: border-box">
                        <ErrorOptionView :quiz-detail="errorInfo.quiz"></ErrorOptionView>
                    </div>
                </el-collapse-item>
            </el-collapse>
        </div>

    </div>
</template>
<script>
import {
    checkAndInitClassRecord,
    getStudentClassContents,
    getREClassInfo,
    checkClassApplication,
    createREClassApplication,
    getStudentErrorList
} from '../../api/eae'
import { Loading } from "element-ui";
import {
    getUserId
} from '../../utils/store'
import ErrorOptionView from './components/ErrorOptionView.vue';
export default ({
    name: 'index',
    components: { ErrorOptionView },
    data() {
        return {
            activeName: '1',
            showDataType: 'progress',
            student_id: getUserId(),
            project_code: this.$route.query.project_code,
            class_id: this.$route.query.class_id,
            classInfo: {
                class_info: {}
            },
            classApplicationInfo: {},
            contentList: [],

            errorList: []
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {

        formatProgress(row){
            return parseInt(row.answer_count / row.quiz_count * 100) +'%'
        },

        exchangeShowDataType() {
            if (this.showDataType == 'progress') {
                this.showDataType = 'error_collection'
                this.requestErrorList()
            } else {
                this.showDataType = 'progress'
            }
        },

        requestErrorList() {
            if(this.errorList.length > 0  ){
                return
            }
            let loadingInstance = Loading.service({
                background: "rgba(0, 0, 0, 0.8)",
                spinner: "el-icon-loading",
                text: 'Loading Data...',
            });
            getStudentErrorList(this.student_id, this.class_id).then((res) => {
                this.errorList = res.data.data
                loadingInstance.close()
            })
        },

        gotoCourseHome() {
            checkAndInitClassRecord(this.student_id, this.class_id).then((res) => {
                let result = res.data.data
                if (result.data_valid == false) {
                    this.$message.warning('This Class No Data！')
                    return
                }
                this.$router.push({
                    path: '/course_content',
                    query: {
                        class_id: this.class_id,
                        record_id: result._id.$id
                    }
                })
            })
        },


        gotoOrderView() {
            createREClassApplication({
                project_code: this.project_code,
                student_id: this.student_id,
                class_id: this.class_id,
            }).then((res) => {
                let result = res.data.data
                this.$router.push({
                    path: '/home/official_course/order',
                    query: {
                        application_id: result._id.$id
                    }
                })
            })
        },

        fetchData() {

            let loadingInstance = Loading.service({
                background: "rgba(0, 0, 0, 0.8)",
                spinner: "el-icon-loading",
                text: 'Loading Data...',
            });



            getREClassInfo(this.class_id).then((res) => {
                this.classInfo = res.data.data
            })
            checkClassApplication({
                project_code: this.project_code,
                student_id: this.student_id,
                class_id: this.class_id,
            }).then((res) => {
                this.classApplicationInfo = res.data.data
            })

            getStudentClassContents(
                this.student_id, this.class_id).then((res) => {
                    this.contentList = res.data.data
                    loadingInstance.close()
                })
        },
        goBack() {
            this.$router.go(-1)
        },
        tableCellstyle() {
            return 'background:  #FAE3E4;border:1px solid white;text-align:center; font-family: Ubuntu-Bold;'
        },
    }
})
</script>
<style scoped lang="scss">
/deep/ .el-table .custom-row-bg {
    background: #FAE3E4;
}

/deep/ .el-table tbody .cell {
    font-family: Ubuntu-Light;
}

/deep/ .el-table .custom-head-cell {
    background: var(--c-primary);
    color: white;
    text-align: center;
    border: 1px solid white;
    box-sizing: border-box;
    font-family: Ubuntu-Bold;
}

.class-content {
    margin: 25px 0px;
    width: 100%;
    height: 120px;
    border: 1px solid #E6D7D7;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 10px 20px;
    display: flex;
    align-items: center;

    .left-container {
        flex: 1;

        .desc {

            color: #4A0700;
            font-size: 12px;
            font-family: centurygothic;
            line-height: 20px;
        }
    }

    .right-content {
        width: 120px;
    }
}


::-webkit-scrollbar {
    display: none;
}



.error-container {
    position: relative;
    width: 100%;
    margin: 0 auto;
    max-width: 1920px;
    height: 92vh;
    overflow-y: auto;
}

.error-list {
    margin-left: 40px;
    font-size: 28px;
    // font-family: AlibabaPuHuiTi-2-75-SemiBold;
    font-weight: 600;
    color: #092E7F
}

/deep/ .el-collapse {
    border-bottom-color: transparent;
    border-top-color: transparent;
}

/deep/ .el-collapse.is-active {
    border-bottom-color: transparent;
    border-top-color: transparent;
}

/deep/ .el-collapse-item__header {
    padding: 20px 20px;
    box-sizing: border-box;
    height: unset;
    min-height: 60px;
    box-shadow: 0px 3px 6px 1px rgba(9, 46, 127, 0.08);
    border-radius: 10px;
    display: flex;
    align-items: center;
    background-color: #F2F6FF;
    color: #FF6450;
    cursor: pointer;
    border-bottom-width: 0px;
    border-bottom-color: transparent;
    font-size: 16px;
    line-height: 28px;
    font-weight: 500;
    transition: border-bottom-color .3s;
    outline: 0;
}

/deep/ .el-collapse-item__header.is-active {
    border-bottom-color: transparent;
}
</style>